import React from "react"
import classNames from "classnames"
function createHtml(html) {
  return { __html: html }
}
const showdown = require("showdown")
const converter = new showdown.Converter()

function ReasonsWhy(props) {
  if (!props.post.reasonsWhySection.hasThisSection) return <></>

  let reasons = []
  props.post.reasonsWhySection.reasonsWhyColumn.forEach(col =>
    reasons.push(...col)
  )

  const reasonsClassNames = classNames({
    "body-section reasons-why-section": true,
    "color-back": !props.invert,
    "invert-link-color": props.invert
  })

  return (
    <div className={reasonsClassNames}>
      <div className="columns">
        <div className="column is-5"></div>
        <div
          className="column"
          dangerouslySetInnerHTML={createHtml(
            converter.makeHtml(props.post.reasonsWhySection.heading)
          )}
        ></div>
        <div className="column is-5"></div>
      </div>

      <div className="columns is-desktop">
        <div className="column is-2"></div>
        <div className="column reasons-why-column">
          {reasons.map((r, i) => (
            <div key={i} className="reasons-why-item columns">
              <div className="column is-4 is-hidden-mobile">
                <span className="reason-number-circle">{r.reason.number}</span>
              </div>

              <div className="column">
                <h5>{r.reason.heading}</h5>
                <p>{r.reason.blurb}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="column is-2"></div>
      </div>
    </div>
  )
}

export default ReasonsWhy
