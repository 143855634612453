import React from "react"
import ImageMeta from "../../components/ImageMeta"
import Layout from "../../components/layout"
import Seamless from "../../components/Seamless"
import { SharedStateProvider } from "../../components/SharedState/PageSharedState"

import TheaterVideo from "@bit/azheng.joshua-tree.theater"
import Button from "@bit/azheng.joshua-tree.button"
import ButtonGroup from "@bit/azheng.joshua-tree.button-group"
import CTA from "../../components/CTA/CTA"
import SEO from "../../components/seo"
import TextPhoto from "../../components/TextPhoto/TextPhoto"
import { graphql } from "gatsby"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext
} from "cloudinary-react"
import { Link } from "gatsby"
import { instructions } from "../../cms/collections/instructions"

var classNames = require("classnames")

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
var converter = new showdown.Converter()

function MeetOurDoctors(props) {
  if (!props.post.meetOurDoctors.hasThisSection) return <></>

  const data = props.post.meetOurDoctors
  const docLength = data.images.length
  const esDocClass = props.language === "es" ? "doc-info-es" : ""

  let docChunks = [[], [], []]
  data.images.forEach((dr, i) => {
    // if (docChunks[0].length >= 2) {
    //   docChunks[1].push(dr)
    // } else if (docChunks[1].length >= 2) {
    //   docChunks[2].push(dr)
    // } else {
    //   docChunks[0].push(dr)
    // }
    if (i < 2) {
      docChunks[0].push(dr)
    } else if (i < 4) {
      docChunks[1].push(dr)
    } else {
      docChunks[2].push(dr)
    }
  })

  return (
    <section className={`body-section doc-info color-back ${esDocClass}`}>
      <div className="columns">
        <div className="column is-4"></div>
        <div
          className="column has-text-centered-tablet"
          dangerouslySetInnerHTML={createHtml(converter.makeHtml(data.text))}
        ></div>
        <div className="column is-4"></div>
      </div>
      <div className={`our-docs is-centered has-${docLength}-docs`}>
        {/* <div className="column is-2"></div> */}

        <TwoOnThree doctors={docChunks} />
        {/* {docLength === 1 ? (
          <>
            <div className="column"></div>
            {data.images.map((image, i) => (
              <>
                <div key={image.doctor.url} className="column is-7">
                  <Link to={image.doctor.url}>
                    <ImageMeta
                      cloudName="nuvolum"
                      publicId={image.doctor.imageId}
                      width="auto"
                      responsive
                      className="meet-our-doctor"
                    ></ImageMeta>
                  </Link>
                  <Link to={image.doctor.url}>
                    <h5 className="image-caption doctor">
                      {image.doctor.caption}
                    </h5>
                  </Link>
                </div>
              </>
            ))}
            <div className="column"></div>
          </>
        ) : (
        // <div className="column">
        //   <div className="columns is-multiline even-doctors">
        <>
            {props.columns === 2 && (
              <>
                {data.images.map((image, i) => {
                  let doctorUrl = image.doctor.url;
                  if (props.language === "es") {
                    doctorUrl = "/" + doctorUrl
                  }
                  return (
                    <>
                      <div key={image.doctor.url} className="column is-12">
                        <Link to={doctorUrl}>
                          <ImageMeta
                            cloudName="nuvolum"
                            publicId={image.doctor.imageId}
                            width="auto"
                            responsive
                            className="meet-our-doctor"
                          ></ImageMeta>
                        </Link>
                        <Link to={doctorUrl}>
                          <h5 className="image-caption doctor">
                            {image.doctor.caption}
                          </h5>
                        </Link>
                      </div>
                    </>
                  )
                }
                )}
              </>
            )}
            </>
          //   </div>
          // </div>
        )}
        {docLength === 3 && (
          <>
            <div className="column is-2"></div>
            {data.images.map((image, i) => (
              <>
                <div key={image.doctor.url} className="column">
                  <Link to={image.doctor.url}>
                    <ImageMeta
                      cloudName="nuvolum"
                      publicId={image.doctor.imageId}
                      width="auto"
                      responsive
                      className="meet-our-doctor"
                    ></ImageMeta>
                  </Link>
                  <Link to={image.doctor.url}>
                    <h5 className="image-caption doctor">
                      {image.doctor.caption}
                    </h5>
                  </Link>
                </div>
                {i < 2 && <div className="column is-1"></div>}
              </>
            ))}
            <div className="column is-2"></div>
          </>
        )}
        {docLength === 5 && (
          <>
            {data.images.map((image, i) => {
              return (
                <>
                  {i % 2 === 0 && <div className="column is-4"></div>}
                  <div
                    key={image.doctor.url}
                    className="column is-7 doc-column"
                  >
                    <Link to={image.doctor.url}>
                      <ImageMeta
                        cloudName="nuvolum"
                        publicId={image.doctor.imageId}
                        width="auto"
                        responsive
                        className="meet-our-doctor"
                      ></ImageMeta>
                    </Link>
                    <Link to={image.doctor.url}>
                      <h5 className="image-caption doctor">
                        {image.doctor.caption}
                      </h5>
                    </Link>
                  </div>
                  {i % 2 === 0 && <div className="column is-2"></div>}
                  {i % 2 === 1 && <div className="column is-4"></div>}
                </>
              )
            })}
          </>
        )} */}
        {/* <div className="column is-2"></div> */}
      </div>
    </section>
  )
}

function TwoOnThree({ doctors }) {
  const styles = {
    captions: {
      display: "flex",
      justifyContent: "space-between"
    },
    learnMore: {
      fontWeight: 600,
      fontSize: "14px",
      textDecoration: "underline"
    },
    docName: {
      fontSize: "14px"
    }
  }
  return (
    <>
      <div className="columns is-centered" style={{ marginTop: "40px" }}>
        {doctors[0] &&
          doctors[0].map((image, i) => (
            <React.Fragment key={i}>
              <div className="column is-6 doc-column">
                <Link to={image.doctor.url}>
                  <ImageMeta
                    cloudName="nuvolum"
                    publicId={image.doctor.imageId}
                    width="auto"
                    responsive
                    className="meet-our-doctor"
                  ></ImageMeta>
                </Link>

                <div className="docs-2-3-caption" style={styles.captions}>
                  <Link to={image.doctor.url}>
                    <h5 className="image-caption doctor" style={styles.docName}>
                      {image.doctor.caption}
                    </h5>
                  </Link>

                  <Link to={image.doctor.url}>
                    <h5
                      className="image-caption doctor learn"
                      style={styles.learnMore}
                    >
                      Learn More
                    </h5>
                  </Link>
                </div>
              </div>
              {i !== doctors[0].length - 1 && (
                <div className="column is-1"></div>
              )}
            </React.Fragment>
          ))}
      </div>

      <div className="columns is-centered">
        {doctors[1] &&
          doctors[1].map((image, i) => (
            <React.Fragment key={i}>
              <div className="column is-6 doc-column">
                <Link to={image.doctor.url}>
                  <ImageMeta
                    cloudName="nuvolum"
                    publicId={image.doctor.imageId}
                    width="auto"
                    responsive
                    className="meet-our-doctor"
                  ></ImageMeta>
                </Link>

                <div className="docs-2-3-caption" style={styles.captions}>
                  <Link to={image.doctor.url}>
                    <h5 className="image-caption doctor" style={styles.docName}>
                      {image.doctor.caption}
                    </h5>
                  </Link>

                  <Link to={image.doctor.url}>
                    <h5
                      className="image-caption doctor learn"
                      style={styles.learnMore}
                    >
                      Learn More
                    </h5>
                  </Link>
                </div>
              </div>
              {i !== doctors[1].length - 1 && (
                <div className="column is-1"></div>
              )}
            </React.Fragment>
          ))}
      </div>

      <div className="columns is-centered">
        {doctors[2] &&
          doctors[2].map((image, i) => (
            <React.Fragment key={i}>
              <div className="column is-6 doc-column">
                <Link to={image.doctor.url}>
                  <ImageMeta
                    cloudName="nuvolum"
                    publicId={image.doctor.imageId}
                    width="auto"
                    responsive
                    className="meet-our-doctor"
                  ></ImageMeta>
                </Link>

                <div className="docs-2-3-caption" style={styles.captions}>
                  <Link to={image.doctor.url}>
                    <h5 className="image-caption doctor" style={styles.docName}>
                      {image.doctor.caption}
                    </h5>
                  </Link>

                  <Link to={image.doctor.url}>
                    <h5
                      className="image-caption doctor learn"
                      style={styles.learnMore}
                    >
                      Learn More
                    </h5>
                  </Link>
                </div>
              </div>
              {i !== doctors[2].length - 1 && (
                <div className="column is-1"></div>
              )}
            </React.Fragment>
          ))}
      </div>
    </>
  )
}

export default MeetOurDoctors
